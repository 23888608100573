<template>
  <div class="Login" id="Login" name="Login">
    <MyMenu :menuname="'login'"></MyMenu>
    <!-- 中部 -->
    <div class="Login_main">
      <div class="Login_main_w">
        <div class="Login_main_fl"><img src="https://file.juuzu.com/dllogo.jpg"></div>
        <div class="Login_main_rl">
          <div class="title">
            <div class="ps" v-if="pathName == 'RetrievePassword' || pathName == 'Login'">
              <span class="retrieve">{{pathName == 'Login'?'手机快速登录':'找回密码'}}</span>
              <span class="back hover" @click="goLogin">返回登录></span>
            </div>
            <div class="lr" v-else>
              <span :class="pathName =='AccountLogin' || pathName =='Login'?'check':'hover'" @click="goLogin">登录</span>
              <span class="fen">|</span>
              <span :class="pathName =='Register'?'check':'hover'"  @click="Register">注册</span>
            </div>
          </div>
          <login @setUserInfo="setUserInfo" v-if="pathName == 'Login'"></login>
          <Register @setUserInfo="setUserInfo" v-if="pathName == 'Register'"></Register>
          <AccountLogin @setUserInfo="setUserInfo" v-if="pathName == 'AccountLogin'"></AccountLogin>
          <RetrievePassword v-if="pathName == 'RetrievePassword'"></RetrievePassword>
          <div class="verification">
            <el-link type="primary" @click="codeLogin">手机验证码</el-link>
            <el-link type="primary" @click="setPassword">忘记密码</el-link>
          </div>
          <div class="divider">
            <el-divider>
              其他方式登录
            </el-divider>
            <div class="iconfont icon-weixin weixin"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->

    <div class="Login_footer">
      <MyFooter></MyFooter>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import Login from './item/Login.vue'
import Register from './item/Register.vue'
import AccountLogin from "./item/AccountLogin.vue"
import RetrievePassword from "./item/RetrievePassword.vue"

export default {
  components: { Login, Register, AccountLogin, RetrievePassword },
  data() {
    return {
      pathName: 'AccountLogin'
    }
  },
  watch: {},
  mounted() {
    this.$watch('$route', (val) => {
      // 监听路由变化
      this.pathName = val.name
    })
  },

  created() {},
  methods: {
    ...mapActions(["setUser", "setToken","setShowLogin"]),
    Register() {
      this.pathName = 'Register'
      // this.$router.push({ path: '/Register' })
    },
    goLogin() {
      this.pathName = 'AccountLogin'
      // this.$router.push({ path: '/login' })
    },
    codeLogin(){
      this.pathName = 'Login'
    },
    setPassword(){
      this.pathName = 'RetrievePassword'
    },
    setUserInfo(mobile,token){
      // 登录信息存到本地
      let user = "*******" + mobile.slice(-4);
      localStorage.setItem("user", user);
      // 登录信息存到vuex
      this.setUser(user);
      localStorage.setItem("token", token);
      // 登录信息存到vuex
      this.setToken(token);
    }
  }
}
</script>
<style scoped lang="scss">
#myLogin {
  padding: 0px 50px;
}
#myLogin /deep/ .el-input-group__append .el-button{
  width: 100px;
  background-color: #409EFF;
  color: #fff;
}
.Login_header {
  width: 1430px;
  overflow: hidden;
  margin: 20px auto;
}
.Login_header img {
  width: 200px;
  float: left;
}
.Login_header ul {
  float: right;
  margin-top: 15px;
  font-size: 14px;
  color: #666;
}
.Login_header ul li {
  float: left;
  padding: 0 10px;
  line-height: 18px;
}
.Login_header ul li + li {
  border-left: 1px solid #666;
}

.Login_main {
  width: 100%;
  background: #fafafa;
}
.Login_main_w {
  width: 1430px;
  margin: 0 auto;
  padding: 50px 0;
  overflow: hidden;
}
.Login_main_fl {
  width: 800px;
  height: 400px;
  float: left;
  img{
    width: 100%;
    height: 100%;
  }
}
.Login_main_rl {
  width: 500px;
  background: #fff;
  float: right;
  .title{
    font-size: 18px;
    padding: 20px;
    margin: 20px 30px 0 30px;
    .lr{
      .check{
        color: #409eff;
      }
    }
    .ps{
      display: flex;
      justify-content: space-between;
      .retrieve{
        color: #1b1f23;
      }
      .back{
        color: #409eff;
        font-size: 12px;
      }
    }
    .hover{
      cursor: pointer;
    }
    .fen{
      margin: 0 10px;
    }
  }
}
.divider, .verification{
  padding: 0 50px;
}
.verification{
  display: flex;
  justify-content: space-between;
}
.divider{
  .weixin{
    color: #28c445;
    font-size: 50px;
    text-align: center;
    margin: 15px 0;
  }
}
</style>
