import Axios from 'axios'
// // 登录
// export const login = (data) => Axios({
//     url: '/zuwu-api/official/login',
//     method: 'post',
//     data
// });
// // 获取验证码
// export const getCode = (data) => Axios({
//     url: '/zuwu-api/official/sms',
//     method: 'post',
//     data
// });

// 账号密码登录
export const loginWithPassword = (data) => Axios({
    url: '/zuwu-api/web/login/loginWithPassword',
    method: 'post',
    data
});
// 获取验证码
export const smsByLogin = (data) => Axios({
    url: '/zuwu-api/web/login/sms',
    method: 'post',
    data
});

// 注册
export const userRegister = (data) => Axios({
    url: '/zuwu-api/web/login/userRegister',
    method: 'post',
    data
});
// 验证码登录
export const login = (data) => Axios({
    url: '/zuwu-api/web/login/login',
    method: 'post',
    data
});

// 找回密码
export const retrievePassword = (data) => Axios({
    url: '/zuwu-api/web/login/retrievePassword',
    method: 'post',
    data
});
