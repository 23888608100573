<template>
  <div id="myLogin">
    <el-form :model="LoginUser" :rules="rules"  ref="ruleForm" class="demo-ruleForm">
      <el-form-item prop="mobile">
        <el-input prefix-icon="el-icon-user-solid"  placeholder="请输入账号/手机号" v-model="LoginUser.mobile" clearable></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input  prefix-icon="el-icon-lock" placeholder="请输入密码" v-model="LoginUser.password" show-password clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="medium" type="primary" @click="Login" style="width:100%;">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {validatePass,validatePhone} from '@/util/validator'
import {loginWithPassword} from '@/api/login/index'
export default {
  name: 'AccountLogin',
  data() {
    return {
      LoginUser:{
        mobile:'',
        password:''
      },
      rules:{
        password: [{ required: true, trigger: 'blur', message: '密码以字母开头,长度在6-18之间,允许字母数字和下划线', validator: validatePass}],
        mobile: [{ required: true, trigger: 'blur', message: '请输入正确的手机号', validator: validatePhone}],
      }
    }
  },
  computed: {},
  methods: {
    Login() {
      this.$refs["ruleForm"].validate(valid => {
        if(valid){
          loginWithPassword(this.LoginUser).then(({data})=>{
            if(data.code == 200){
              this.$emit('setUserInfo',this.LoginUser.mobile,data.data)
              this.$message.success('登录成功')
              this.$router.push('/home')
            }else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>