<template>
  <div id="myLogin">
<!--    <h2>用户登录</h2>-->
    <el-form :model="LoginUser" :rules="rules" ref="ruleForm" class="demo-ruleForm">
      <el-form-item prop="mobile">
        <el-input prefix-icon="el-icon-user-solid" placeholder="请输入手机号" v-model="LoginUser.mobile"></el-input>
      </el-form-item>
      <el-form-item prop="verCode">
        <el-input
          prefix-icon="el-icon-view"
          placeholder="请输入验证码"
          v-model="LoginUser.verCode"
        >
          <el-button slot="append" type="text" @click="getVerifyCode" :loading="downCode != 120"><span class="verify">{{downCode ==120 ? "获取验证码" :`${downCode}s` }}</span></el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="medium" type="primary" @click="Login" style="width:100%;">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {login, smsByLogin} from '@/api/login/index'
import {validatePhone} from "@/util/validator";

export default {
  name: 'MyLogin',
  data() {
    return {
      timer: null,
      downCode:120,
      LoginUser: {
        mobile: '',
        verCode: ''
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules:{
        verCode: [{ required: true, trigger: 'blur', message: '请输入验证码'}],
        mobile: [{ required: true, trigger: 'blur', message: '请输入正确的手机号', validator: validatePhone}],
      }
    }
  },
  computed: {},
  methods: {
    Login() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate(valid => {
        //如果通过校验开始登录
        if (valid) {
          login(this.LoginUser).then(({data}) => {
              if (data.code == 200) {
                this.$emit('setUserInfo',this.LoginUser.mobile,data.data)
                this.$message.success('登录成功')
                this.$router.push('/home')
                // // 隐藏登录组件
                // this.isLogin = false;
                // // 登录信息存到本地
                // let user = "*******" + mobile.slice(-4);
                // localStorage.setItem("user", user);
                // // 登录信息存到vuex
                // this.setUser(user);
                // let token = data.data;
                // localStorage.setItem("token", token);
                // // 登录信息存到vuex
                // this.setToken(token);
                // // 弹出通知框提示登录成功信息
                // this.$message.success(data.msg)
                // this.$router.go(-1)
                // // 清空输入框的校验状态
                // this.$refs["ruleForm"].resetFields();
              } else {
                this.$message.error(data.msg)
              }
            })
        }
      });
    },
    getVerifyCode(){
      let userNameRule = /^1[3-9]\d{9}$/
      if(userNameRule.test(this.LoginUser.mobile)){
        smsByLogin({mobile:this.LoginUser.mobile}).then(({data})=>{
          if(data.code==200){
            this.$message.success('发送成功')
            this.timer =  setInterval(()=>{
              this.downCode--
              if(this.downCode==0){
                this.downCode = 120
                clearInterval(this.timer)
              }
            },1000)
          }else{
            this.$message({
              message: data.msg,
              type: 'warning'
            });
          }
        })
      }else{
        this.$message.warning('请输入正确的手机号')
      }
    }
  }
}
</script>
<style scoped>
</style>
