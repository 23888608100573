<template>
  <div id="myLogin">
    <el-form :model="userInfo" :rules="rules" ref="ruleForm" class="demo-ruleForm">
      <el-form-item prop="mobile">
        <el-input prefix-icon="el-icon-user-solid" placeholder="请输入手机号" v-model="userInfo.mobile" clearable></el-input>
      </el-form-item>
      <el-form-item prop="verCode">
        <el-input
            clearable
            prefix-icon="el-icon-view"
            placeholder="请输入验证码"
            v-model="userInfo.verCode"
        >
          <el-button slot="append" type="text" @click="getVerifyCode" :loading="downCode != 120"><span class="verify">{{downCode ==120 ? "获取验证码" :`${downCode}s` }}</span></el-button>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input prefix-icon="el-icon-lock" placeholder="请设置6-18位密码" v-model="userInfo.password" show-password clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-input prefix-icon="el-icon-lock" placeholder="请再次确定密码" v-model="confirmPass" show-password clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="medium" type="primary" @click="submit" style="width:100%;">修改密码</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {validatePass, validatePhone} from "@/util/validator";
import {retrievePassword, smsByLogin} from '@/api/login/index'
export default {
  name: 'RetrievePassword',
  data() {
    return {
      timer: null,
      downCode:120,
      confirmPass:"",
      userInfo: {
        mobile: '',
        password: '',
        verCode:''
      },
      rules:{
        password: [{ required: true, trigger: 'blur', message: '密码以字母开头,长度在6-18之间,允许字母数字和下划线', validator: validatePass}],
        verCode: [{ required: true, trigger: 'blur', message: '请输入验证码'}],
        mobile: [{ required: true, trigger: 'blur', message: '请输入正确的手机号', validator: validatePhone}],
      }
    }
  },
  computed: {},
  methods: {
    submit() {
      this.$refs["ruleForm"].validate(valid => {
        if(valid){
          if(!this.confirmPass){
            this.$message.error('请再次确认密码')
            return
          }
          if(this.userInfo.password != this.confirmPass){
            this.$message.error('两次输入密码不一致')
            return;
          }
          retrievePassword(this.userInfo).then(({data}) => {
            console.log(data,'2323')
            if (data.code == 200) {
              this.$message.success('修改成功')
              this.$router.push({ path: "/Login" })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    },
    getVerifyCode(){
      let userNameRule = /^1[3-9]\d{9}$/
      if(userNameRule.test(this.userInfo.mobile)){
        smsByLogin({mobile:this.userInfo.mobile}).then(({data})=>{
          if(data.code==200){
            this.$message.success('发送成功')
            this.timer =  setInterval(()=>{
              this.downCode--
              if(this.downCode==0){
                this.downCode = 120
                clearInterval(this.timer)
              }
            },1000)
          }else{
            this.$message.warning(data.msg)
          }
        })
      }else{
        this.$message.warning('请输入正确的手机号！')
      }
    }
  }
}
</script>

<style scoped>

</style>