import { render, staticRenderFns } from "./AccountLogin.vue?vue&type=template&id=0bc1fb99&scoped=true&"
import script from "./AccountLogin.vue?vue&type=script&lang=js&"
export * from "./AccountLogin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc1fb99",
  null
  
)

export default component.exports