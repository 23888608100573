<template>
  <div id="myLogin">
<!--    <h2>立即注册</h2>-->
    <el-form :model="RegisterUser" :rules="rules"  ref="ruleForm" class="demo-ruleForm">
      <el-form-item prop="mobile">
        <el-input prefix-icon="el-icon-user-solid" placeholder="请输入手机号" v-model="RegisterUser.mobile" clearable></el-input>
      </el-form-item>
      <el-form-item prop="verCode">
        <el-input
          prefix-icon="el-icon-view"
          placeholder="请输入验证码"
          v-model="RegisterUser.verCode"
          clearable
        >
          <el-button slot="append" type="text" @click="getVerifyCode" :loading="downCode != 120"><span class="verify">{{downCode ==120 ? "获取验证码" :`${downCode}s` }}</span></el-button>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input prefix-icon="el-icon-lock" placeholder="请输入6-18位密码" v-model="RegisterUser.password" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="medium" type="primary" @click="Register" style="width:100%;">注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {smsByLogin,userRegister} from '@/api/login/index'
import {validatePass, validatePhone} from '@/util/validator'
export default {
  name: 'Register',
  data() {
    return {
      timer: null,
      downCode:120,
      RegisterUser: {
        mobile: '',
        password: '',
        verCode:''
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
        rules:{
          password: [{ required: true, trigger: 'blur', message: '密码以字母开头,长度在6-18之间,允许字母数字和下划线', validator: validatePass}],
          verCode: [{ required: true, trigger: 'blur', message: '请输入验证码'}],
          mobile: [{ required: true, trigger: 'blur', message: '请输入正确的手机号', validator: validatePhone}],
        }
    }
  },
  computed: {},
  methods: {
    Register() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate(valid => {
        //如果通过校验开始登录
        if (valid) {
          userRegister(this.RegisterUser).then(({data}) => {
              if (data.code == 200) {
                this.$emit('setUserInfo',this.RegisterUser.mobile,data.data)
                this.$message.success('注册成功')
                this.$router.push('/home')
              } else {
                this.$message.error(data.msg)
              }
            })
        }
      });
    },
    getVerifyCode(){
      let userNameRule = /^1[3-9]\d{9}$/
      if(userNameRule.test(this.RegisterUser.mobile)){
        smsByLogin({mobile:this.RegisterUser.mobile}).then(({data})=>{
          if(data.code==200){
            this.$message.success('发送成功')
            this.timer =  setInterval(()=>{
              this.downCode--
              if(this.downCode==0){
                this.downCode = 120
                clearInterval(this.timer)
              }
            },1000)
          }else{
            this.$message.warning(data.msg)
          }
        })
      }else{
        this.$message.warning('请输入正确的手机号！')
      }
    }
  }
}
</script>
<style scoped>
</style>
